import { FC } from 'react';
import ConditionsProperties from '../../../form-builder/ConditionsProperties';
import { EditorProps } from '../../../form-builder/FormBuilderTypes';
import PreviewProperties from '../../../form-builder/PreviewProperties';
import ActionEditorBase from '../ActionEditorBase';

const DateEditor: FC<EditorProps> = (props) => {
  return (
    <div data-cy="date-editor">
      <ActionEditorBase {...props}></ActionEditorBase>

      <ConditionsProperties {...props} />

      <PreviewProperties {...props} enableDocumentNumbering />
    </div>
  );
};

export default DateEditor;
